body,p{
  margin: 0;
  padding: 0 calc(18px - (100vw - 100%)) 0 0;
  overflow-y: scroll;

}
.w-100{
  width: 100%;
}
.w-60{
  width: 60%;
}
.h-100{
  height: 100vh;
}
.h-50px{
  height: 50px;
}
.h-60px{
  height: 60px;
}
.cy{
  display: flex;
  align-items: center;
}
.cx{
  display: flex;
  justify-content: center;
}
.text{
  color: #bdbec0;
  font-family: Arial,Helvetica,sans-serif;
}
.mt-minus5{
  margin-top: -5px;
}
.mt-1{
  margin-top: 1rem;
}
.mt-2{
  margin-top: 2rem;
}
.mt-3{
  margin-top: 1.5rem;
}
.mb-minus5{
  margin-bottom: -5px;
}
.mb-1{
  margin-bottom: 0.5rem;
}
.mb-3{
  margin-bottom: 1.5rem;
}
.mb-3px{
  margin-bottom: 3px;
}
.mb-5px{
  margin-bottom: 5px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-50{
  margin-bottom: 50px;
}
.mt-5px{
  margin-top: 5px;
}
.mt-7px{
  margin-top: 7px;
}
.mt-4{
  margin-top: 2rem;
}
.mt-5{
  margin-top: 3rem;
}
.mt-10{
  margin-top: 10px;
}
.mt-30{
  margin-top: 30px;
}
.p-20{
  padding: 20px;
}
.pt-2{
  padding-top: 1rem;
}
.pt-1{
  padding-top: 0.5rem;
}
.pl-5{
  padding-left: 5px;
}
.pl-30{
  padding-left: 30px;
}
.pb-1{
  padding-bottom: 0.5rem;
}
.pb-2{
  padding-bottom: 1rem;
}
.pb-5{
  padding-bottom: 5px;
}
.pb-15{
  padding-bottom: 15px;
}
.px-2{
  padding-left: 1rem;
  padding-right: 1rem;
}
.pt-3{
  padding-top: 1.5rem;
}
.my-1{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mx-1{
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-5{
  margin-left: 5px;
  margin-right: 5px;
}
.ml-1{
  margin-left: 1rem;
}
.ml-5{
  margin-left: 5px;
}
.ml-10{
  margin-left: 10px;
}
.mr-1{
  margin-right: 1rem;
}
.mr-5{
  margin-right: 5px;
}
.mr-10{
  margin-right: 10px;
}
.mr-15{
  margin-right: 15px;
}
.py-1{
  padding: 1rem 0 1rem 0;
}
.py-1-m{
  padding: 0.5rem 0 0.5rem 0;
}
.py-10{
  padding-top: 10px;
  padding-bottom: 10px;
}
.px-5{
  padding-left: 5px;
  padding-right: 5px;
}
.px-10{
  padding-left: 10px;
  padding-right: 10px;
}
.px-20{
  padding-left: 20px;
  padding-right: 20px;
}
.color-line {
  background-color: #00364c;
  height: 4px;
  margin-left: 48%;
  margin-right: 48%;
}
.heading-text{
  font-family: GoogleSans-Regular, Arial, Helvetica, sans-serif;
  font-size: 2.3rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #151515;
}
.mini-heading{
  font-size: calc(1.2rem + 0.1vw);
  font-weight: 300;
  font-family: GoogleSans-Regular, Arial, Helvetica, sans-serif;
  text-align: center;
  color: #212529;
}
.mini-text{
  font-family: GoogleSans-Regular, Arial, Helvetica, sans-serif;
  text-align: center;
  color: #3c4043;
}
.lightboxOverlay{
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
}
.mx-10{
  margin: 0 10px 0 10px;
}
.text-bold{
  font-weight: bold;
}
.text-bolder{
  font-weight: bolder;
}
.link{
  color: blue;
}
img-comparison-slider {
  --handle-opacity-active: 0.5;
  cursor: grab;
  outline: none;
}
.cursor-pointer{
  cursor: pointer;
}
.text-center{
  text-align: center;
}
.text-justify{
  text-align: justify;
}
.text-end{
  text-align: end;
}
.df{
  display: flex;
}
.cursor-default{
  cursor: default;
}
.pl-20{
  padding-left: 20px;
}
.pt-20{
  padding-top: 20px;
}
.pt-30{
  padding-top: 30px;
}
.pb-20{
  padding-bottom: 20px;
}
.pb-30{
  padding-bottom: 30px;
}
.pr-5{
  padding-right: 5px;
}
.pr-20{
  padding-right: 20px;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@font-face {
  font-family: GeoC;
  src: url("/assets/fonts/geoc.ttf") format("truetype");
}
.no-actions{
  pointer-events: none;
}
.flex-column{
  flex-direction: column;
}
.pos-r{
  position: relative;
}
.ovf-auto{
  overflow: auto;
}
.space-between{
  justify-content: space-between;
}
